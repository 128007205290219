import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';

function App() {
	useEffect(() => {
		window.location.href = 'https://www.admin.ggs.io/';
	}, []);

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				p: '2rem',
				alignContent: 'center',
				height: '100vh',
				textAlign: 'center',
				textWrap: 'balance',
			}}
		>
			<Typography variant="h4" maxWidth="58rem">
				Este sitio ya no está disponible. Por favor, acceda a la nueva
				versión de la aplicación en:
			</Typography>
			<Typography variant="h3" maxWidth="58rem">
				<a href="https://www.admin.ggs.io/">
					https://www.admin.ggs.io/
				</a>
			</Typography>
		</Box>
	);
}

export default App;
